import Test from "./components/Test.vue";
import Home from "./components/FrontPage/Home.vue";
import Search from "./components/Search/Search.vue";
import BehandlerProfilePreview from "./components/BehandlerProfilePreview.vue";
import BehandlerShowFullBlog from "./components/BehandlerShowFullBlog.vue";
import BehandlerPreviewV2 from "./components/BehandlerPreviewV2.vue";
import Signup from "./components/SignUp/Index.vue";
import VerifyPractitioner from "./components/SignUp/VerifyPractitioner.vue";
import VerifyVote from "./components/Rating/VerifyVote.vue";
import BehandlerPreviewV2ReadMore from "./components/BehandlerPreviewV2ReadMore.vue";
import VerifyBooking from "./components/Booking/VerifyBooking.vue";
import CancelBooking from "./components/Booking/CancelBooking.vue";
import About from "./components/About.vue";
import Calendar from "./components/Calendar/Calendar.vue";
import LiveEvent1 from "./components/LiveEvent1.vue";
import LiveEvent1Tilmeld from "./components/LiveEvent1Tilmeld.vue";
import AdminIndex from "./components/Admin/Index.vue";
import LiveEventTilmeldinger from "./components/Admin/Tilmeldinger.vue";
// import Redirecter from "./Redirecter.vue";

export default [
	{
		path: "/",
		name: "Frontpage",
		component: Home,
	},
	{
		path: "/search",
		name: "Search",
		component: Search,
	},
	{
		path: "/root",
		name: "Root",
		component: Test,
	},
	{
		path: "/livedag",
		name: "LiveEvent1",
		component: LiveEvent1,
	},
	{
		path: "/livedag-tilmeld",
		name: "LiveEvent1Tilmeld",
		component: LiveEvent1Tilmeld,
	},
	{
		path: "/om-minsundhed",
		name: "About",
		component: About,
	},
	{
		path: "/verifypractitioner",
		name: "VerifyPractitioner",
		component: VerifyPractitioner,
	},
	{
		path: "/voting/verify",
		name: "VerifyVote",
		component: VerifyVote,
	},
	{
		path: "/booking/verify",
		name: "VerifyBooking",
		component: VerifyBooking,
	},
	{
		path: "/booking/cancelbooking",
		name: "CancelBooking",
		component: CancelBooking,
	},
	{
		path: "/behandler/:id/kalender",
		name: "Calendar",
		title: "Calendar",
		component: Calendar,
	},
	{
		path: "/pro",
		name: "CreatePractitionerWelcomePage",
		title: "preview",
		component: BehandlerPreviewV2,
	},
	{
		path: "/pro/laesmere",
		name: "ProReadMore",
		title: "Læs mere",
		component: BehandlerPreviewV2ReadMore,
	},
	{
		path: "/behandler/:id",
		name: "BehandlerProfilePreview",
		component: BehandlerProfilePreview,
	},
	{
		path: "/opret",
		name: "SignUp",
		component: Signup,
	},
	{
		path: "/behandler/:id/blogs",
		name: "BehandlerShowFullBlog",
		component: BehandlerShowFullBlog,
	},
	{
		path: "/behandler/:id/blog/:entryid",
		name: "BehandlerShowFullBlog",
		component: BehandlerShowFullBlog,
		alias: [
			'/behandler/:id/event/:entryid',
			'/behandler/:id/billeder/:entryid',
		]
	},
	// {
	// 	path: '/:catchAll(.*)',
	// 	name: "Redirecter",
	// 	component: Redirecter,
	// },
	/***********************************************************************
	ADMIN
	/**********************************************************************/
	{
		path: "/admin",
		name: "AdminIndex",
		component: AdminIndex,
	},
	{
		path: "/admin/tilmeldinger",
		name: "Tilmeldinger",
		component: LiveEventTilmeldinger,
	}
];
